<template>
  <v-card class="ma-2 pa-md-3 pa-1">
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12" lg="3">
            <v-checkbox v-model="modelObj.configuration.showasmodule">
              <template v-slot:label>
                <div>
                  Consider as Module
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    Shows in Main Menu
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col v-if="modelObj.configuration.showasmodule">
            <v-row>
              <v-col cols="6" sm="3">
                <v-text-field :disabled="modelObj.isinternal" label="Module Name" outlined dense hide-details v-model="modelObj.configuration.modulename"></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-autocomplete :disabled="modelObj.isinternal" v-model="modelObj.configuration.moduleicon" :items="iconsList" label="Module Icon" outlined dense hide-details>
                  <template v-slot:append-outer v-if="modelObj.configuration.moduleicon">
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon> {{modelObj.configuration.moduleicon}}</v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <v-icon>{{data.item}}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title >{{data.item}}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3">
                <v-autocomplete small-chips deletable-chips v-model="modelObj.configuration.relatedModulesClone" :items="listOfForms" label="Related Modules"
                  multiple outlined dense hide-details item-text="name" item-value="_id" :item-disabled="checkIsOptionInternal">
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field disabled v-model="modelObj.configuration.collectionName" outlined dense hide-details>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="bordered--table" fixed-header dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="30%">User/Role</th>
                        <th width="40%">Permission Level</th>
                        <th width="10%">
                          <v-btn color="success" text fab dark x-small class="mt-1 pb-0" @click="modelObj.configuration.permissions.push({}); reInit++">
                            <v-icon dark>mdi-plus-circle</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-for="(item, index) in modelObj.configuration.permissions" :key="`${index}_${reInit}`" class="border pt-3">
                      <tr class="border_bottom">
                        <td>
                          <v-autocomplete hide-details dense outlined :items="userRolesList" item-value="_id" item-text="name" v-model="item.role"></v-autocomplete>
                        </td>
                        <td>
                          <v-layout  class="d-flex">
                            <v-checkbox primary class="ma-0" hide-details dense label="Read" value="read" v-model="item.cando" multiple></v-checkbox>
                            <v-checkbox primary class="ma-0" hide-details dense label="Write" value="write" v-model="item.cando" multiple></v-checkbox>
                            <v-checkbox primary class="ma-0" hide-details dense label="Delete" value="delete" v-model="item.cando" multiple></v-checkbox>
                          </v-layout>
                        </td>
                        <td>
                          <v-btn color="black" text fab dark x-small class="mt-1 pb-0" @click="modelObj.configuration.permissions.splice(index, 1); reInit++">
                            <v-icon dark>mdi-minus-circle</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="3">
            <v-checkbox v-model="modelObj.configuration.isopenform" :disabled="modelObj.isinternal">
              <template v-slot:label>
                <div>
                  Is Open Form
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    Any one can fill form (Login no needed)
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12"  sm="6" v-if="modelObj.configuration.isopenform">
            <v-text-field dense v-model="formLink" outlined clearable label="Shareable Link">
              <template v-slot:append-outer>
                <v-btn @click="generateOpenFormLink" small tile color="primary" class="pt-0 mt-n2">
                  Generate New
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="3">
            <v-checkbox v-model="modelObj.configuration.limitresponse" :disabled="modelObj.isinternal">
              <template v-slot:label>
                <div>
                  Limit to 1 Response
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    Users will be required to Sign in to App.
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['modelObj', 'formId'],
  data () {
    return {
      reInit: 0,
      formLink: '',
      listOfForms: [],
      userRolesList: [],
      iconsList: [
        'mdi-access-point',
        'mdi-animation',
        'mdi-file-document',
        'mdi-nut',
        'mdi-google-earth',
        'mdi-google-circles-extended',
        'mdi-gauge',
        'mdi-apple-safari',
        'mdi-assistant',
        'mdi-camera-timer',
        'mdi-file-table-box-multiple',
        'mdi-marker-check',
        'mdi-shield-star',
        'mdi-folder-cog',
        'mdi-slot-machine',
        'mdi-clipboard-list',
        'mdi-artstation',
        'mdi-package-variant',
        'mdi-pac-man',
        'mdi-dlna',
        'mdi-rhombus-split',
        'mdi-semantic-web',
        'mdi-card-bulleted',
        'mdi-file-table-box-multiple-outline',
        'mdi-castle',
        'mdi-truck-outline',
        'mdi-movie-open',
        'mdi-subway-variant',
        'mdi-sale',
        'mdi-qrcode',
        'mdi-protocol',
        'mdi-noodles',
        'mdi-card-bulleted-outline',
        'mdi-fire-truck',
        'mdi-shield-airplane',
        'mdi-cassette',
        'mdi-cast',
        'mdi-shield-half-full',
        'mdi-shield-edit-outline',
        'mdi-shield-lock',
        'mdi-message-bulleted',
        'mdi-flask-empty-outline',
        'mdi-shield-star-outline',
        'mdi-chart-areaspline',
        'mdi-chart-donut',
        'mdi-flower-tulip',
        'mdi-floppy',
        'mdi-sign-text',
        'mdi-silo',
        'mdi-silverware-clean',
        'mdi-folder-key',
        'mdi-solar-panel',
        'mdi-nature',
        'mdi-spotlight',
        'mdi-newspaper-variant',
        'mdi-star-box-multiple-outline',
        'mdi-nfc-variant',
        'mdi-fuse',
        'mdi-store-outline',
        'mdi-arrange-bring-forward',
        'mdi-compass-outline',
        'mdi-origin',
        'mdi-bag-personal-outline',
        'mdi-peace',
        'mdi-hospital-building',
        'mdi-image-broken-variant'
      ]
    }
  },
  mounted () {
    this.getUserRoles()
    this.getListOfForms()
    if (this.modelObj.configuration && this.modelObj.configuration.openFormToken) {
      this.formLink = `${process.env.VUE_APP_WEB_URL}/openform/${this.modelObj.configuration.openFormToken}`
    }
    if (!this.modelObj.configuration.permissions) this.modelObj.configuration.permissions = []
  },
  methods: {
    checkIsOptionInternal (item) {
      return item.isinternalModule
    },
    getListOfForms () {
      this.$_execute('get', 'forms').then(({ data }) => {
        if (!this.modelObj.configuration.relatedModules) this.modelObj.configuration.relatedModules = []
        const internalModuleIds = this.modelObj.configuration.relatedModules.filter(x => x.isinternal).map(x => x.formId)
        data.forEach(x => {
          x.isinternalModule = Boolean(internalModuleIds.includes(x._id))
        })
        this.modelObj.configuration.relatedModulesClone = this.modelObj.configuration.relatedModules.map(x => x.formId)
        this.listOfForms = data
      })
    },
    getUserRoles() {
      this.$_execute('get', `user_roles`).then(({ data }) => {
        this.userRolesList = data
      })
    },
    generateOpenFormLink () {
      this.$_execute('get', `forms/generate_new_url/${this.modelObj._id}`).then(({ data }) => {
        this.modelObj.configuration.openFormToken = data;
        this.formLink = `${process.env.VUE_APP_WEB_URL}/openform/${data}`
      })
    }
  }
}
</script>

<style>

</style>